// src/logger.js
import pino from 'pino';

const isProduction = process.env.NODE_ENV === 'production';
const isDev = !isProduction;

const prettyConfig = {
  colorize: true,
  translateTime: 'SYS:standard',
  ignore: 'pid,hostname',
  messageFormat: '{msg} {req} {res}',
  singleLine: false, // Disable single-line for multi-line formatting
  hideObject: false, // Ensure objects are not hidden
};

const logger = pino({
  level: isDev ? 'debug' : 'warn',
  transport: isDev
    ? {
        target: 'pino-pretty',
        options: prettyConfig,
      }
    : undefined,
  browser: {
    asObject: true,
  },
});

export default logger;
