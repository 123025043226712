export const sortMenuItems = (menuItems) => {
  return menuItems.sort((a, b) => {
    // Sort by menu
    if (a.menu < b.menu) return -1;
    if (a.menu > b.menu) return 1;

    // Sort by menu_section (null should be at the top)
    if (!a.menu_section && Boolean(b.menu_section)) return -1;
    if (Boolean(a.menu_section) && !b.menu_section) return 1;
    if (a.menu_section < b.menu_section) return -1;
    if (a.menu_section > b.menu_section) return 1;

    // If menu_name and menu_section are equal, then sort by menu_item
    if (a.menu_item < b.menu_item) return -1;
    if (a.menu_item > b.menu_item) return 1;

    return 0;
  });
};
