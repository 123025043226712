import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./config/Config";
import { LicenseInfo } from "@mui/x-license-pro";
import { UserProvider } from "./contexts/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

Amplify.configure(awsConfig);
LicenseInfo.setLicenseKey(
  "affa03090e60b7933e177a9e0ac8e5c4Tz05NTk1MSxFPTE3NTUwMzM4NjkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

const MemoizedApp = React.memo(App); // <-- Place the memoization here

// 1) Get a reference to the div with ID root
const el = document.getElementById("root");

// 2) Tell React to take control of that element
const root = ReactDOM.createRoot(el);

// Supress warnings in production version
//console.warn = () => {};

// 3) Show the component on the screen
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        limit={3}
      />
      <UserProvider>
        <MemoizedApp /> {/* <-- Use the memoized component here */}
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
